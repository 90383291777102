export const ruleAddQuota = {
  date_from: {
    required: true,
    message: 'Заполните дату',
    trigger: 'blur',
  },
  date_to: {
    required: true,
    message: 'Заполните время',
    trigger: 'blur',
  },
  time_from: {
    required: true,
    message: 'Заполните дату',
    trigger: 'change',
  },
  time_to: {
    required: true,
    message: 'Заполните время',
    trigger: 'change',
  },
  count: {
    required: true,
    message: 'Заполните квоту для культуры',
    trigger: 'blur',
  },
  totalCount: {
    required: true,
    message: 'Заполните ожидаемое количество машин',
    trigger: 'blur',
  },
  truck_type: {
    required: true,
    message: 'Выберите тип автомобилей',
    trigger: 'blur',
  },
  truck_type_codes: {
    type: 'array',
    required: true,
    message: 'Выберите тип автомобилей',
    trigger: 'blur',
  },
  culture_id: {
    required: true,
    message: 'Выберите тип культуры',
    trigger: 'blur',
  },
  supplier_id: {
    required: true,
    message: 'Выберите поставщика',
    trigger: 'blur',
  },
}
