<template>
  <span>
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      class="form-edit-quota"
      label-width="200px"
      @submit.native.prevent
    >
      <div class="form-edit-quota__time-content" style="margin-bottom: -6px">
        <div class="form-edit-quota__time-wrap">
          <div class="form-edit-quota__input-label">с</div>

          <el-form-item class="form-edit-quota__input-wrap" prop="date_from">
            <el-date-picker
              v-model="ruleForm.date_from"
              :clearable="false"
              type="date"
              format="dd.MM.yyyy"
              value-format="yyyy-MM-dd"
              placeholder=""
              :picker-options="pickerOptions"
              size="small"
              class="form-edit-quota__input form-edit-quota__input--date"
              @change="handleCheckDate"
            />
          </el-form-item>

          <el-form-item class="form-edit-quota__input-wrap" prop="time_from">
            <el-time-select
              v-model="ruleForm.time_from"
              :clearable="false"
              size="small"
              :picker-options="pikerOptionsTimeFrom"
              class="form-edit-quota__input form-edit-quota__input--time"
              @change="handleSetTime"
            />
          </el-form-item>
        </div>

        <div class="form-edit-quota__time-wrap">
          <div class="form-edit-quota__input-label">по</div>

          <el-form-item class="form-edit-quota__input-wrap" prop="date_to">
            <el-date-picker
              v-model="ruleForm.date_to"
              :clearable="false"
              type="date"
              format="dd.MM.yyyy"
              value-format="yyyy-MM-dd"
              placeholder=""
              :picker-options="pickerOptionsDateTo"
              size="small"
              :disabled="isDisableDateTo"
              class="form-edit-quota__input form-edit-quota__input--date"
              @change="handleCheckDateTo"
            />
          </el-form-item>

          <el-form-item class="form-edit-quota__input-wrap" prop="time_to">
            <el-time-select
              v-model="ruleForm.time_to"
              :clearable="false"
              size="small"
              :disabled="isDisableTimeTo"
              :picker-options="pikerOptionsTimeTo"
              class="form-edit-quota__input form-edit-quota__input--time"
              @change="handleSetTime"
            />
          </el-form-item>
        </div>
      </div>
      <div style="margin-bottom: 15px; width: 100%; margin-left: 20px">
        <el-button class="link-btn-date-now" type="text" @click="setDateNow">
          Прямо сейчас
        </el-button>
      </div>
      <el-form-item
        label-width="200px"
        class="form-edit-quota__count-wrap"
        label="Культура"
        :rules="rules.culture_id"
        size="small"
        prop="culture_id"
      >
        <el-select
          v-model="ruleForm.culture_id"
          placeholder="Выберите культуру"
          :class="{ 'w-100p': isMobile }"
        >
          <el-option
            v-for="option in culture"
            :key="option.id"
            :label="option.name"
            :value="option.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label-width="200px"
        class="form-edit-quota__count-wrap"
        label="Поставщик"
        :rules="rules.supplier_id"
        size="small"
      >
        <el-select
          v-model="ruleForm.supplier_id"
          placeholder="Выберите поставщика"
          :class="{ 'w-100p': isMobile }"
        >
          <el-option
            v-for="option in suppliersList"
            :key="option.id"
            :label="option.name"
            :value="option.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item
        label-width="200px"
        class="form-edit-quota__count-wrap"
        label="Количество авто в буфере"
        prop="count"
        :rules="rules.count"
      >
        <el-input-number
          v-model="ruleForm.count"
          controls-position="right"
          :min="1"
          size="small"
          :class="{ 'w-100p': isMobile }"
        />
      </el-form-item>

      <el-form-item
        label-width="200px"
        class="form-edit-quota__count-wrap"
        label="Настроить тип авто"
        :rules="rules.truck_type_codes"
        size="small"
        prop="truck_type_codes"
      >
        <el-checkbox-group
          v-model="ruleForm.truck_type_codes"
          :class="['truck-types', { 'w-100p': isMobile }]"
        >
          <el-checkbox
            v-for="type in autoTypeSupervisor"
            :key="type.id"
            :label="type.val"
          >
            {{ type.name }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-form>
  </span>
</template>

<script>
import { DATE_FORMAT, GET_TIME, MILLISECOND_IN_DAY } from '@/constants/date'
import { GET_CULTURE_FROM_STATE } from '@/views/control/store/actions'
import { GET_REQUEST_STATUS } from '@/store/actions'
import { GET_SUPPLIERS_ALL } from '@/views/exporter/store/actions'
import {
  addHourTz,
  addIntervalDateTz,
  addIntervalTimeTz,
  diffSupervisorDates,
  diffTime,
  getDateToUnix,
  getFormattedTzNow,
  getLocalUnixFromTzBriefDate,
  getQuotaTzDate,
  getTime,
  getTzTime,
  nowTz,
  pickerOptions,
  timeConvert,
} from '@/core'
import { autoTypeSupervisor } from '@/constants/auto-type'
import { mapGetters } from 'vuex'
import { ruleAddQuota } from './data'

export default {
  name: 'FormEditQuota',
  props: {
    itemQuota: {
      type: Object,
      default: () => ({}),
    },
    deleted: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    add: {
      type: Boolean,
      default: false,
    },
    sendRequest: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      autoTypeSupervisor,
      ruleForm: {
        id: undefined,
        culture_id: 'all',
        supplier_id: undefined,
        count: 1,
        totalCount: undefined,
        date_from: null,
        date_to: null,
        time_from: '',
        time_to: '',
        truck_type_codes: [],
        itemQuotaId: null,
        unload_line_id: null,
      },
      suppliersList: [],
      culture: [],
      rules: ruleAddQuota,
      pickerOptions,
      pickerOptionsDateTo: {
        disabledDate: time =>
          getLocalUnixFromTzBriefDate(this.ruleForm?.date_from) -
            time.getTime() >=
            MILLISECOND_IN_DAY ||
          time.getTime() -
            getLocalUnixFromTzBriefDate(this.ruleForm?.date_from) >=
            MILLISECOND_IN_DAY * 2,
      },
    }
  },

  computed: {
    ...mapGetters({
      cultureList: GET_CULTURE_FROM_STATE,
      suppliers: GET_SUPPLIERS_ALL,
      requestStatus: GET_REQUEST_STATUS,
    }),
    pikerOptionsTimeFrom() {
      return {
        start: '00:00',
        step: '00:15',
        end: '23:45',
        minTime: this.getMinTimeFrom,
      }
    },
    pikerOptionsTimeTo() {
      return {
        start: '00:00',
        step: '00:15',
        end: '23:45',
        minTime: this.getMinTimeTo,
      }
    },
    isDisableDateTo() {
      return !this.ruleForm.date_from || this.ruleForm.date_from === ''
    },
    isDisableTimeTo() {
      return (
        this.ruleForm.time_from === '' ||
        !this.requestStatus ||
        !this.ruleForm.date_to
      )
    },
    getMinTimeFrom() {
      if (
        getTzTime(this.ruleForm.date_from, DATE_FORMAT) ===
        getTzTime(nowTz(), DATE_FORMAT)
      ) {
        return getTzTime(nowTz(), GET_TIME)
      }

      return ''
    },
    getMinTimeTo() {
      if (
        this.ruleForm.time_from &&
        getTzTime(this.ruleForm.date_from, DATE_FORMAT) ===
          getTzTime(this.ruleForm.date_to, DATE_FORMAT)
      ) {
        let hours = Number(this.ruleForm.time_from.slice(0, 2))
        let minutes =
          Number(this.ruleForm.time_from.slice(3, 5)) + hours * 60 + 45

        return this.timeConvert(minutes)
      }

      return ''
    },
    allTruckTypesOptions() {
      return autoTypeSupervisor.map(item => item.val)
    },
  },
  watch: {
    itemQuota: {
      immediate: true,
      handler(newVal) {
        this.setFormFields(
          newVal.culture_id,
          getTime(newVal.time_from || nowTz(), DATE_FORMAT),
          getTime(newVal.time_to || nowTz(), DATE_FORMAT),
          getTime(newVal.time_from, GET_TIME),
          getTime(newVal.time_to, GET_TIME),
          newVal.buffer_quota,
          // при создании квоты изначально отмечены все типы авто,
          // при редактировании - то, что заполнено в сохраненной квоте
          newVal.created_at ? newVal.truck_types : this.allTruckTypesOptions,
          newVal.suppliers_ids,
          newVal.id,
          newVal.unload_line_id,
        )
      },
    },
    suppliers: {
      immediate: true,
      handler(newVal) {
        newVal
          ? (this.suppliersList = [
              { id: null, name: 'Без поставщика' },
              ...newVal,
            ])
          : (this.suppliersList = [{ id: null, name: 'Без поставщика' }])
      },
    },
    cultureList: {
      immediate: true,
      handler(newVal) {
        let prepare = newVal.map(item => ({
          deleted_at: item.deleted_at,
          harvest_year: item.harvest_year,
          id: item.id,
          name: `${item.name}  ${
            item.harvest_year ? item.harvest_year + ' года' : ''
          } `,
          unload_id: item.unload_id,
        }))

        this.culture = [
          {
            id: 'all',
            name: 'Любая культура',
          },
          ...prepare,
        ]
      },
    },
    requestStatus: {
      immediate: true,
      handler(newVal) {
        if (newVal === false) {
          this.setFormFields()
        }
      },
    },
    sendRequest(newVal) {
      if (newVal && this.add) {
        this.handleSubmitForm('add')
      }

      if (newVal && this.edit) {
        this.handleSubmitForm()
      }
    },
    deleted: {
      immediate: true,
      handler(newVal) {
        if (newVal === true) {
          this.$emit('delete', this.itemQuota.id)
        }
      },
    },
  },
  methods: {
    timeConvert,
    getQuotaTzDate,
    getDateToUnix,
    getTime,
    setDateNow() {
      // если уже установлен временной интервал - передвигаем его,
      // иначе ставим время действия 1 час по дефолту
      let duration = 0

      if (this.ruleForm.time_to) {
        duration = diffSupervisorDates(
          this.ruleForm.date_from,
          this.ruleForm.time_from,
          this.ruleForm.date_to,
          this.ruleForm.time_to,
        )
      }
      this.ruleForm.date_from = getFormattedTzNow()
      this.ruleForm.time_from = getTzTime(nowTz(), GET_TIME)

      if (duration) {
        this.ruleForm.time_to = addIntervalTimeTz(
          this.ruleForm.date_from,
          this.ruleForm.time_from,
          duration,
        )
        this.ruleForm.date_to = addIntervalDateTz(
          this.ruleForm.date_from,
          this.ruleForm.time_from,
          duration,
        )
      } else {
        this.ruleForm.time_to = addHourTz(nowTz(), GET_TIME)
      }
    },
    getExporterName(id) {
      let name = null

      this.suppliers.map(item => {
        if (item.id === id) {
          name = item.id
        }
      })

      return name
    },

    handleSubmitForm() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let data = this.setRequestData(this.ruleForm)

          if (this.add) {
            this.$emit('add', data)
          }
          if (this.edit) {
            this.$emit('editQuota', data)
          }
        }
      })
    },
    setRequestData(data) {
      return {
        unload_line_id: data.unload_line_id || null,
        time_from: this.getQuotaTzDate(data.date_from, data.time_from),
        time_to: this.getQuotaTzDate(data.date_to, data.time_to),
        suppliers_ids: data.supplier_id ? [data.supplier_id] : [],
        buffer_quota: data.count,
        truck_type_codes: data.truck_type_codes,
        culture_id: data.culture_id === 'all' ? null : data.culture_id,
        id: data.itemQuotaId,
      }
    },
    setFormFields(
      culture_id = 'all',
      date_from = null,
      date_to = null,
      time_from = '',
      time_to = '',
      count = 1,
      truck_type_codes = [],
      suppliers_ids = [],
      itemQuotaId,
      unload_line_id,
    ) {
      this.ruleForm.culture_id = culture_id === null ? 'all' : culture_id
      this.ruleForm.date_from = date_from
      this.ruleForm.date_to = date_to
      this.ruleForm.time_from = time_from || getTzTime(nowTz(), GET_TIME)
      this.ruleForm.time_to = time_to
      this.ruleForm.count = count
      this.ruleForm.truck_type_codes = truck_type_codes
      this.$refs.ruleForm?.clearValidate()
      this.ruleForm.itemQuotaId = itemQuotaId
      this.ruleForm.unload_line_id = unload_line_id
      this.ruleForm.supplier_id = this.getExporterName(suppliers_ids[0])
    },
    beforeClose() {
      this.setFormFields()
    },
    handleCheckDate() {
      this.ruleForm.date_to = ''
      this.ruleForm.time_from = ''
      this.handleSetTime()
    },
    handleCheckDateTo(event) {
      if (this.ruleForm.date_from === event) {
        this.handleSetTime()
      }
    },
    handleSetTime() {
      const { date_from, date_to, time_from, time_to, count } = this.ruleForm
      let diffTimeFromTo = ''

      if (date_from && date_to && time_from && time_to && count) {
        const totalTimeFrom = `${this.getTime(
          date_from,
          DATE_FORMAT,
        )} ${time_from}`
        const totalTimeTo = `${this.getTime(date_to, DATE_FORMAT)} ${time_to}`

        diffTimeFromTo = diffTime(totalTimeTo, totalTimeFrom, 'minutes')
      }
      diffTimeFromTo <= 0 ? (this.ruleForm.time_to = '') : null
    },
  },
}
</script>

<style lang="sass">
.link-btn-date-now
  color: $btn-orange !important
  > span
    color: $btn-orange !important
    border-bottom: 1px solid $btn-orange !important
.form-edit-quota

  &__time-content
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 15px

  &__time-wrap
    display: flex
    justify-content: flex-start
    align-items: center
    & + &
      margin-left: 15px

  &__input-wrap
    margin-bottom: 0 !important
    .el-form-item__content
      margin-left: 0 !important

  &__input-label
    margin-right: 10px
  &__select-culture
    label
      width: 200px!important
      text-align: left
    .el-form-item__content
      margin-left: 0 !important
    .el-select
      flex: 1!important
  &__input
    &--date.el-input
      margin-right: 10px
      width: 130px
    &--time.el-input
      width: 95px

  &__count-wrap
    display: flex
    justify-content: space-between
    .el-form-item__label
      text-align: left
    .el-form-item__content
      margin-left: 0 !important
      flex: 1
    .el-select
      width: 100%
    .el-input-number
      width: 100%

  &__collapse-title
    font-size: 20px
    line-height: 1
    margin-bottom: 5px
    color: #606266
  &__btns
    display: flex
    justify-content: space-between
    align-items: center
  &__btn
    width: 48%

  &__collapse-subtitle
    line-height: 1
    color: #606266

  .el-collapse-item__content
    padding: 10px 0

    .el-form-item
      margin-bottom: 0

    .el-form-item__content
      margin-left: 0 !important

    .el-radio-group
      display: flex
      justify-content: flex-start
      align-items: flex-start
      flex-wrap: wrap
      .el-radio
        flex: 0 0 100%
        margin: 0 0 10px 0
        display: flex
        flex-direction: row-reverse
        justify-content: space-between
        &.is-bordered.is-checked
          border-color: $color-blue-light

    .el-radio__input.is-checked .el-radio__inner
      border-color: $color-blue-light
      background: $color-blue-light

    .el-radio__input.is-checked+.el-radio__label
      color: $color-blue-light

  .el-select .el-input.is-disabled .el-input__inner
    background-color: #F5F7FA !important
    border-color: #E4E7ED !important
    color: #C0C4CC !important
    cursor: not-allowed

.truck-types
  display: flex
  flex-direction: column
.form-edit-quota .form-edit-quota__input.form-edit-quota__input--date.el-input--suffix .el-input__inner,
.form-edit-quota .form-edit-quota__input.form-edit-quota__input--time.el-input--suffix .el-input__inner
  padding-right: 0
</style>
